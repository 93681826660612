import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/Login.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/serverCenter",
    name: "serverCenter",
    component: () => import("../views/ServerCenter.vue"),
  },
  {
    path: "/Center",
    name: "center",
    component: () => import("../views/Center.vue"),
    redirect: "/setting",
    children: [
      {
        path: "/setting",
        component: () => import("../views/setting.vue"),
      },
      {
        path: "/item",
        component: () => import("../views/Item/list.vue"),
      },
      {
        path: "/article",
        component: () => import("../views/Article/list.vue"),
      },
      {
        path: "/notice",
        component: () => import("../views/Notice/list.vue"),
      },
      {
        path: "/images",
        component: () => import("../views/Images/list.vue"),
      },
      {
        path: "/member",
        component: () => import("../views/Member/list.vue"),
      },
      {
        path: "/area",
        component: () => import("../views/Member/area.vue"),
      },
      {
        path: "/message",
        component: () => import("../views/Message/list.vue"),
      },
      {
        path: "/order",
        component: () => import("../views/Order/list.vue"),
      },
      {
        path: "/deposit",
        component: () => import("../views/deposit/list.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
