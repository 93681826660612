import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  httpResponseData,
} from "axios";
import { de, get, post, put } from "@/plugins/Kan/Base";

enum requestType {
  get,
  post,
  put,
  file,
  delete,
}

class KanRequest {
  private KanRequestServe!: AxiosInstance;
  private api = "/api/";
  private readonly config = {
    timeout: 10000,
    headers: {
      "Kan-Auth": "kanlai",
      "Kan-Token": <boolean | string>false,
    },
  };

  constructor(config?: object) {
    this.config = Object.assign(this.config, config);
    this.KanRequestServe = axios.create(this.config);
  }

  get: get = (url) => {
    return this.sendRequest(requestType.get, url, {});
  };
  put: put = async (url, params) => {
    return this.sendRequest(requestType.put, url, params);
  };
  delete: de = async (url) => {
    return this.sendRequest(requestType.delete, url, {});
  };
  post: post = async (url, params) => {
    return this.sendRequest(requestType.post, url, params);
  };
  private asAxiosError = <T>(data: AxiosError): httpResponseData<T> => {
    return {
      code: data.response?.status,
      message: data.message,
      data: {},
    } as httpResponseData<T>;
  };
  private sendRequest = async (
    type: requestType,
    url: string,
    params: object = {}
  ) => {
    try {
      this.config.headers["Kan-Token"] =
        sessionStorage.getItem("token") || false;
      let response: AxiosResponse;
      url = this.api + url;
      switch (type) {
        case requestType.file:
          response = await this.KanRequestServe.post(url, params, this.config);
          return response.data;
        case requestType.get:
          response = await this.KanRequestServe.get(url, this.config);
          return response.data;
        case requestType.put:
          response = await this.KanRequestServe.put(url, params, this.config);
          return response.data;
        case requestType.post:
          response = await this.KanRequestServe.post(url, params, this.config);
          return response.data;
        case requestType.delete:
          response = await this.KanRequestServe.delete(url, this.config);
          return response.data;
      }
    } catch (e) {
      console.log(e);
      const data = e as AxiosError;
      return this.asAxiosError(data);
    }
  };
  log = <T>(message?: T): void => {
    console.log(
      "%c%s",
      "background:SeaGreen;padding:5px;color:#ffffff; border-radius: 5px;",
      "提示",
      message
    );
  };
}

const kan = new KanRequest();

export { kan };
