import { defineStore } from "pinia";
export const useMainStore = defineStore("main", {
  state: () => ({
    counter: 0,
    name: "Eduardo",
    isLogin: false,
    admin: {
      datetime: "1970-01-02 18:12:03",
      id: 1,
      name: "管理员",
      password: "",
      token: "=",
      username: "",
    },
  }),
  actions: {
    updateAdmin(admin: any) {
      this.admin = admin;
      this.isLogin = true;
      sessionStorage.setItem("token", admin.token);
    },
  },
});
