import { createApp } from "vue";
import App from "./App.vue";
import ViewUIPlus from "view-ui-plus";
import router from "./router";
import { createPinia } from "pinia";
import "view-ui-plus/dist/styles/viewuiplus.css";
import "./assets/style.scss";

const app = createApp(App);
app.use(router).use(ViewUIPlus).use(createPinia()).mount("#app");
